<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(step, index) in steps">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{step}}</v-stepper-step>
                <v-divider v-if="index !== steps.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <v-layout justify-center fill-height>
                    <v-flex xs12 sm8 md4>
                        <v-card-text>
                            <v-form ref="form" v-model="valid">
                                <v-text-field outline clearable prepend-icon="kitchen" name="boxId" label="櫃體編號" type="tel"
                                              v-model="boxId" mask="######" :rules="boxIdRules" data-cy="boxIdField" required>
                                </v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn large block color="primary" :disabled="!valid" @click="getSetting" data-cy="boxSettingSubmitBtn">送出</v-btn>
                        </v-card-actions>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2">
                <v-layout>
                    <v-flex xs12 sm6 offset-sm3>
                        <v-card flat v-if="setting">
                            <v-list subheader two-line>
                                <v-subheader class="subheading">
                                    運作狀態 &nbsp;
                                    <v-chip small dark v-if="gettingBoxStatus" color="success">
                                        取得狀態中 &nbsp;&nbsp;
                                        <v-progress-circular indeterminate size="12" width="2" color="white" :value="getboxstatustimeout"></v-progress-circular>
                                    </v-chip>
                                    <v-chip small dark v-else :color="boxStatusInfo.color" @click="getBoxStatus">
                                        {{ boxStatusInfo.msg }}
                                    </v-chip>
                                </v-subheader>
                                <v-alert outline :value="warningMsg" color="warning">{{warningMsg}}</v-alert>
                                <template v-for="(item, index) in filteredBoxStatus()">
                                    <v-list-tile :key="item.key">
                                        <v-list-tile-content>
                                            <v-list-tile-title> {{ item.key }} </v-list-tile-title>
                                            <v-list-tile-sub-title :class='item.color'>{{ item.value }}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                        <v-list-tile-action v-if="item.actionTitle">
                                            <v-btn color="primary" @click="item.action">{{ item.actionTitle }}</v-btn>
                                        </v-list-tile-action>
                                    </v-list-tile>
                                    <v-divider :key="'status' + index"></v-divider>
                                </template>
                                <v-subheader class="subheading">設定資料</v-subheader>
                                <template v-for="(item, index) in boxInfo">
                                    <v-list-tile :key="item.key">
                                        <v-list-tile-content>
                                            <v-list-tile-title> {{ item.key }} </v-list-tile-title>
                                            <v-list-tile-sub-title>{{ item.value }}</v-list-tile-sub-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider :key="'info' + index"></v-divider>
                                </template>
                            </v-list>
                            <v-card-actions>
                                <v-btn flat block large color="info" @click="setStepNo(1)">
                                    <v-icon small>arrow_back_ios</v-icon>重設櫃號
                                </v-btn>
                                <v-btn block large color="primary" @click="setStepNo(3)">顯示 QR Code</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3">
                <v-layout>
                    <v-flex xs12 sm6 offset-sm3>
                        <v-switch v-model="switchdoor" :label="switchMsg"></v-switch>
                        <p class="text-xs-center subheading mb-0">請使用櫃體掃描器讀取以下 QR Code</p>
                        <div class="text-xs-center">
                            <BoxQRCode :content="qrContent"></BoxQRCode>
                        </div>
                        <v-btn large color="warning" @click="openAllDoors()"><b>{{openAllBtnMsg}}</b></v-btn>
                        <v-btn large color="green" @click="doorsStatus()"><b>{{ $t('doorCheck.door-status') }}</b></v-btn>
                        <v-btn flat block large color="primary" @click="setStepNo(1)">
                            <v-icon small>arrow_back_ios</v-icon>重設櫃號&nbsp;{{boxId}}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="4">
                <v-layout row>
                    <v-flex xs12 sm6 offset-sm3>
                        <v-card flat>
                            <v-card-actions>
                                <v-progress-circular :rorate="-90" :size="60" :width="8" :value="progress" color="teal">
                                    {{progress}}%
                                </v-progress-circular>
                                <v-spacer></v-spacer>
                                <v-btn large color="warning" :disabled="!isFinished||!isSuccess" @click="openAllDoors()">{{openAllBtnMsg}}</v-btn>
                                <v-btn large color="primary" :disabled="!isFinished" @click="finish()">結束</v-btn>
                            </v-card-actions>
                            <v-progress-linear indeterminate v-if="!isFinished" color="primary"></v-progress-linear>
                            <v-card-text class="ma-0 pa-0">
                                <v-list two-line subheader>
                                    <template v-for="(item, index) in reports">
                                        <v-divider v-if="index > 0" :key="index"></v-divider>
                                        <v-list-tile :key="'report'+index">
                                            <v-list-tile-action>
                                                <v-icon large :color="item.icon_color">{{item.action}}</v-icon>
                                            </v-list-tile-action>
                                            <v-list-tile-content :class="item.class">
                                                <v-list-tile-title>{{item.message}}</v-list-tile-title>
                                                <v-list-tile-sub-title>時間&nbsp;&nbsp;{{item.timeStr}}</v-list-tile-sub-title>
                                            </v-list-tile-content>
                                        </v-list-tile>
                                    </template>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
        </v-stepper-items>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout" @close="waitingDialogCloseFunc"></waiting-dialog>
        <message-dialog
            :show.sync="msgDialog.show"
            :msg="msgDialog.msg"
            :primary-btn="msgDialog.primaryBtn"
            @primary="clickMsgDialog"
        ></message-dialog>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import BoxQRCode from '@/components/BoxQRCode'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
import CountdownMixin from '@/mixins/CountdownMixin'
import MQTTMessageWatcher from '@/mixins/MQTTMessageWatcher'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { publish } from '@/utils/MQTTClient'
import { formatDatetime } from '@/utils/utils'

const DOOR_CONTROL_MODE = {
    OPEN: 0,
    OPEN_OK: 2,
    OPEN_ALL: 3,
    DOOR_STATUS: 4
}
const DEFAULT_BOX_ID = '099999'
const ELECTRONIC_MONEY_TYPES = {
    EASYCARD: '00',
    IPASS: '01'
}
const RELAY_TYPES = {
    ROUTER: 'router',
    SLAVE: 'slave'
}
const MANAGE_STATUS = 60000
const SIGNON_TIMEOUT = 60000
const RELAY_TIMEOUT = 120000
const RFID_TIMEOUT = 30000

export default {
    name: 'BoxSetting',
    components: { BoxQRCode, WaitingDialog, MessageDialog },
    mixins: [ WaitDialogControl, MessageDialogControl, ControlResultWatcher, CountdownMixin, MQTTMessageWatcher ],
    data() {
        return {
            steps: ['輸入櫃號', '櫃體資訊', '掃描 QR Code', '設定進度'],
            stepNo: 1,
            valid: false,
            boxId: '',
            boxIdRules: [
                v => !!v || '請輸入櫃體編號',
                v => /\d{6}/.test(v) || '請輸入有效櫃體編號'
            ],
            setting: null,
            switchdoor: true,
            switchMsg: "儲格全開模式",
            reports: [] ,
            boxStatus: null,
            gettingBoxStatus: false,
            getboxstatustimeout: MANAGE_STATUS,
            boxStatusInfo: {
                msg: '',
                color: 'info'
            },
            boxStatusTime: '(未取得回應)',
            watchers: [],
            timeout: null,
            maximumTimeout: null,
            progress: 0,
            isFinished: false,
            isSuccess: false,
            warningMsg: null,
            openAllBtnMsg: '',
            signOnType: null,
            machineType: null,
            waitingDialogCloseFunc: () => {},
            controlMode: 0
        }
    },
    computed: {
        qrContent() {
            if (!this.setting) return {}
            if (this.switchdoor == true) {
                return Object.assign({action: 'setting'}, {
                    box_id: this.setting.box_id,
                    locker_count: this.setting.locker_count,
                    env: this.setting.env
                })
            }
            else {
                return Object.assign({action: 'setting'}, {
                    box_id: this.setting.box_id,
                    locker_count: this.setting.locker_count,
                    env: this.setting.env,
                    close_mode: 1
                })
            }
        },
        boxInfo() {
            if (!this.setting) return null
            var closemode = '開門模式'
            var self = this
            if (self.setting.close_mode == 1 && self.stepNo < 3 ) {
                closemode = '全關模式'
                self.switchdoor = self.switchMode(false)
            } else if (self.stepNo < 3) self.switchdoor = self.switchMode(true)
            return [
                { key: '編號', value: self.setting.box_id },
                { key: '名稱', value: self.setting.name },
                { key: '地址', value: self.setting.country + self.setting.city + self.setting.address },
                { key: '位置', value: self.setting.location },
                { key: '設置', value: closemode},
                { key: '儲格數量', value: self.setting.locker_count },
                { key: '軟體版本', value: self.setting.software_ver },
                { key: '最後回報時間', value: self.setting.report_time || '(未回報)' }
            ]
        }
    },
    watch: {
        switchdoor(newValue) {
            if (newValue == false) {
                this.doorMsg(false)
            } else {
                this.doorMsg(true)
            }
        },
        boxStatus() {
            if (this.boxStatus != null) {
                console.log('box status', this.boxStatus)
                this.boxStatusTime = formatDatetime(new Date(this.boxStatus.time))
                this.stopBoxStatus(false, false)
                if (this.stepNo === 4) {
                    this.isFinished = true
                    this.progress += 5
                    if (this.isFinished) {
                        clearTimeout(this.maximumTimeout)
                    }
                }
            }
        },
        controlResult() {
            this.closeDialog()
            const resultObj = this.controlResult
            console.log(resultObj)
            const wrong_slave = localStorage.getItem('slave')
            if (wrong_slave && wrong_slave.length > 0) {
                store.commit('setSnackBar', { message: '面對櫃體左邊 第 ' + wrong_slave + ' 個櫃子的中控版異常', color: 'error' })
            } else {
                let cell
                if (this.controlMode === DOOR_CONTROL_MODE.OPEN_ALL){
                    let failOpenArray = []
                    let controlOK = true
                    if (this.switchdoor == true) {
                        for( cell in resultObj ) {
                            if(resultObj[cell] !== DOOR_CONTROL_MODE.OPEN_OK) {
                                controlOK = false
                                failOpenArray.push(cell)
                            }
                        }
                    } else {
                        for( cell in resultObj ) {
                            if(resultObj[cell] !== DOOR_CONTROL_MODE.OPEN_ALL) {
                                controlOK = false
                                failOpenArray.push(cell)
                            }
                        }
                    }
                    let failOpenString = failOpenArray.toString()
                    if (controlOK && this.switchdoor == true) {
                        store.commit('setSnackBar', { message: '儲格全開成功', color: 'success' })
                    } else if (controlOK && this.switchdoor == false) {
                        store.commit('setSnackBar', { message: '儲格成功全關閉', color: 'success' })
                    } else if (this.switchdoor == true){
                        this.showMsgDialog(`儲格第 ${failOpenString} 格無法開啟`)
                    } else {
                        this.showMsgDialog(`儲格第 ${failOpenString} 格異常`)
                    }
                } else {
                    let openArray = []
                    let closeArray = []
                    for( cell in resultObj ) {
                        if(resultObj[cell] == DOOR_CONTROL_MODE.OPEN)
                            openArray.push(cell)
                        else
                            closeArray.push(cell)
                    }
                    if (openArray.length > 0 && closeArray.length > 0)
                        store.commit('setSnackBar', { message: openArray.length +'個開啟格' + openArray.toString() + '；' + closeArray.length + '個關上格' + closeArray.toString(), color: 'success' })
                    else if (openArray.length === 0 && closeArray.length > 0)
                        store.commit('setSnackBar', { message: closeArray.length + '個關上格' + closeArray.toString(), color: 'success' })
                    else if (openArray.length > 0 && closeArray.length === 0)
                        store.commit('setSnackBar', { message: openArray.length +'個開啟格' + openArray.toString(), color: 'success' })
                    else
                        store.commit('setSnackBar', { message: '格門狀態無回應', color: 'error' })
                }
            }
            this.controlMode = 0
        },
        countdown() {
            if (this.countdown > 0) {
                this.boxStatusInfo = {
                    msg: this.countdown + ' 秒後自動再次嘗試',
                    color: 'info'
                }
            }
        }
    },
    methods: {
        init() {
            this.stepNo = 1
            this.valid = false
            this.boxId = ''
            this.setting = null
            this.reports = [],
            this.isFinished = false
            this.isSuccess = false
            this.boxStatus = null
            this.boxStatusTime = '(未取得回應)'
            this.warningMsg = null
            this.openAllBtnMsg = ''
            this.signOnType = null
            this.machineType = null
            this.waitingDialogCloseFunc = () => {}
            this.controlMode = 0
        },
        clickMsgDialog() {
            this.closeMsgDialog()
            if (this.stepNo === 4) {
                this.setStepNo(4)
            } else {
                this.setStepNo(2)
            }
        },
        async getSetting() {
            if (this.$refs.form.validate()) {
                store.commit('setTargetBoxId', this.boxId)
                this.showDialog('取得櫃體設定...', '取得櫃體設定失敗')
                try {
                    var suburl = '/api/box/' + this.boxId + '/setting'
                    let response = await axios.get(HostUrl + suburl, getAxiosConfig())
                    if (response.data.code === 0) {
                        this.setting = response.data.setting
                        this.setStepNo(2)
                        this.getBoxStatus()
                    } else {
                        store.commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                    }
                } catch (error) {
                    store.commit('setSnackBar', {
                        message: '伺服器錯誤，請稍後再試',
                        color: 'error'
                    })
                } finally {
                    this.closeDialog()
                }
            } else {
                store.commit('setSnackBar', {
                    message: '輸入資料錯誤, 請修改紅色欄位內容',
                    color: 'error'
                })
            }
        },
        setStepNo(no) {
            if (no === 1) this.init()
            this.stepNo = no
            store.commit('setTitle', this.steps[this.stepNo - 1])
            this.closeDialog()
            clearTimeout(this.timeout)
            clearTimeout(this.maximumTimeout)
            this.stopCountdown()
        },
        switchMode(mode) {
            if (mode == false) {
                this.doorMsg(mode)
                return mode
            }
            else {
                this.doorMsg(mode)
                return mode
            }
        },
        handleMQTTMessage(message) {
            if (message === null) console.log('[ElectronicMoney] MQTT message is Null')
            else if (this.signOnType && message.topic.endsWith('/electronicmoney/signon/report')) this.handleSignOnReport(message)
            else if (this.machineType && message.topic.endsWith('/relay/report')) this.handleRelayReport(message)
            else if (message.topic.endsWith('/rfid/client/report')) this.handleRfidReport(message)
        },
        handleSignOnReport(message) {
            let payload = message.payload
            if (this.signOnType === payload.company) {
                this.closeDialog()
                if (payload.code === '0') {
                    store.commit('setSnackBar', { message: '卡機註冊成功', color: 'success' })
                    this.getBoxStatus(1)
                } else this.showMsgDialog('卡機註冊失敗<br>(錯誤代碼 ' + payload.code + ')', '關閉')
                this.signOnType = null
            }
        },
        handleRfidReport(message) {
            let payload = message.payload
            this.closeDialog()
            if (payload.code === 0) {
                store.commit('setSnackBar', { message: payload.card_number, color: 'success' })
                this.getBoxStatus(1)
            } else this.showMsgDialog('無法讀取', '關閉')
        },
        handleRelayReport(message) {
            let payload = message.payload
            if (this.machineType == payload.machine) {
                this.closeDialog()
                if (payload.code === 0) {
                    if (payload.machine === 'router') store.commit('setSnackBar', { message: '路由器關開成功', color: 'success'})
                    else store.commit('setSnackBar', { message: '中控版關開成功', color: 'success'}) //if(payload.machine == 'slave')
                    this.getBoxStatus(1)
                } else this.showMsgDialog('繼電器控制失敗<br>(錯誤代碼 ' + payload.code + ')', '關閉')
            } else {
                if (payload.code == -901) {
                    this.showMsgDialog('請對調繼電器埠', '關閉')
                }
                else if (payload.code == -902) {
                    this.showMsgDialog('繼電器單埠異常', '關閉')
                } else this.showMsgDialog('無安裝繼電器', '關閉')
            }
            this.machineType = null
        },
        signOn(cardType) {
            let cardName = null
            switch (cardType) {
                case ELECTRONIC_MONEY_TYPES.EASYCARD:
                    cardName = '悠遊卡'
                    break
                case ELECTRONIC_MONEY_TYPES.IPASS:
                    cardName = '一卡通'
                    break
                default:
                    this.showMsgDialog(cardName + ' 無法重新註冊', '關閉')
                    return
            }
            let vm = this
            vm.waitingDialogCloseFunc = () => {
                vm.signOnType = null
                vm.waitingDialogCloseFunc = () => {}
            }
            vm.showDialog('重新註冊' + cardName + '(最多等待' + (SIGNON_TIMEOUT / 1000) + '秒)', cardName + '註冊超過時間上限', SIGNON_TIMEOUT)
            vm.signOnType = cardType
            let topic = 'box/' + vm.boxId + '/electronicmoney/signon'
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                type: cardType
            })
        },
        rfidTest() {
            let vm = this
            vm.waitingDialogCloseFunc = () => {
                vm.machineType = null
                vm.waitingDialogCloseFunc = () => {}
            }
            vm.showDialog('靠卡感應: (最多等待' + (RFID_TIMEOUT / 1000) + '秒)', '超過時間上限', RFID_TIMEOUT)
            let topic = 'box/' + vm.boxId + '/rfid/client'
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId
            })
        },
        relayTest(machineType) {
            let machineName = null
            switch (machineType) {
                case RELAY_TYPES.ROUTER:
                    machineName = '路由器關開'
                    break
                case RELAY_TYPES.SLAVE:
                    machineName = '中控版關開'
                    break
                default:
                    this.showMsgDialog('無法切換[繼電器]開關', '關閉')
                    return
            }
            let vm = this
            vm.waitingDialogCloseFunc = () => {
                vm.machineType = null
                vm.waitingDialogCloseFunc = () => {}
            }
            vm.showDialog('繼電器控制:' + machineName + '(最多等待' + (RELAY_TIMEOUT / 1000) + '秒)', machineName + '超過時間上限', RELAY_TIMEOUT)
            vm.machineType = machineType
            let topic = 'box/' + vm.boxId + '/machine/relay'
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                machine: machineType
            })
        },
        filteredBoxStatus() {
            let result = []
            let vm = this
            if (!vm.boxStatus) {
                result.push({key: '結果', value: '尚未回應', color: 'red--text'})
            } else {
                result.push({key: '結果', value: '成功取得狀態', color: 'success--text'})
                result.push({
                    key: '網路連線',
                    value: vm.boxStatus.status.box_service ? '正常' : '異常 (請檢查網路設定並重開樹莓派)',
                    color: vm.boxStatus.status.box_service ? 'success--text' : 'red--text'
                })
                result.push({
                    key: '掃描器',
                    value: vm.boxStatus.status.scanner ? '正常' : '異常 (請確認掃描器是否正確安裝並重開樹莓派)',
                    color: vm.boxStatus.status.scanner ? 'success--text' : 'red--text'
                })
                result.push({
                    key: '悠遊卡註冊',
                    value: vm.boxStatus.status.ez_signon ? '正常' : '失敗',
                    color: vm.boxStatus.status.ez_signon ? 'success--text' : 'red--text',
                    action: () => vm.signOn(ELECTRONIC_MONEY_TYPES.EASYCARD),
                    actionTitle: '重新註冊'
                })
                result.push({
                    key: '一卡通註冊',
                    value: vm.boxStatus.status.ipass_signon ? '正常' : '失敗',
                    color: vm.boxStatus.status.ipass_signon ? 'success--text' : 'red--text',
                    action: () => vm.signOn(ELECTRONIC_MONEY_TYPES.IPASS),
                    actionTitle: '重新註冊'
                })
                result.push({
                    key: '讀卡機感應',
                    value: vm.boxStatus.status.rfid_card ? '正常' : '失敗',
                    color: vm.boxStatus.status.rfid_card ? 'success--text' : 'red--text',
                    action: () => vm.rfidTest(),
                    actionTitle: '驅動感應'
                })
                result.push({
                    key: '路由器關開',
                    value: vm.boxStatus.status.relay_router ? '正常' : '失敗',
                    color: vm.boxStatus.status.relay_router ? 'success--text' : 'red--text',
                    action: () => vm.relayTest(RELAY_TYPES.ROUTER),
                    actionTitle: '開關切換'
                })
                result.push({
                    key: '中控版關開',
                    value: vm.boxStatus.status.relay_slave ? '正常' : '失敗',
                    color: vm.boxStatus.status.relay_slave ? 'success--text' : 'red--text',
                    action: () => vm.relayTest(RELAY_TYPES.SLAVE),
                    actionTitle: '開關切換'
                })
            }
            result.push({key: '時間', value: vm.boxStatusTime, color: ''})
            return result
        },
        watchBoxStatus() {
            this.watchers.push(store.watch(
                (state, getters) => getters.boxStatusResult,
                (response) => {
                    if (response) {
                        this.boxStatus = response
                    }
                    store.commit('setBoxStatusResult', null)
                }
            ))
        },
        watchReport() {
            this.watchers.push(store.watch(
                (state, getters) => getters.boxSettingResult,
                (report) => {
                    if (report) {
                        if (this.reports.length === 0) {
                            this.setStepNo(4)
                            this.maximumTimeout = setTimeout(this.showTimeoutMsg, 190000)
                        }
                        report.timeStr = new Date().toLocaleTimeString()
                        if (report.success) {
                            report.class = "success--text"
                            report.icon_color = "success"
                            report.action = "check_circle_outline"
                        } else {
                            report.class = "error--text"
                            report.icon_color = "error"
                            report.action = "block"
                        }
                        this.reports.push(report)
                        if((report.step_no === report.step_count) || !report.success) {
                            this.getBoxStatus(1)
                            // //delay 儲格全開按鈕開啟
                            // setTimeout(() => {
                                // this.isFinished = true
                                // this.progress += 5
                            // }, 25000)
                        }
                        // if (this.isFinished) {
                        //     clearTimeout(this.timeout)
                        // }
                        if (report.step_no === report.step_count - 1) this.isSuccess = report.success
                        this.progress = Math.round((report.step_no / (report.step_count * 1.0)) * 100.0) - 5
                    }
                }
            ))
        },
        showTimeoutMsg() {
            //this.showMsgDialog('等待超時, 請檢查櫃體網路設定後回到前頁重新設定或聯絡軟體工程師', '關閉')
            this.getBoxStatus()
        },
        stopBoxStatus(conti=true, timeup=true) {
            if (timeup) {
                this.boxStatus = null
                this.boxStatusTime = '(未取得回應)'
            }
            this.stopCountdown()
            clearTimeout(this.timeout)
            if (conti) this.startCountdown(35, this.getBoxStatus)
            else {
                this.boxStatusInfo = {
                    msg: '重新整理',
                    color: 'info'
                }
            }
            this.gettingBoxStatus = false
        },
        getBoxStatus(ask=0) {
            this.gettingBoxStatus = true
            let targetBoxId
            if (this.setting && this.setting.report_time) targetBoxId = this.boxId
            else {
                targetBoxId = DEFAULT_BOX_ID
                this.warningMsg = '此櫃體未進行設定, 以下為新機運作狀態'
            }
            let topic = 'box/' + targetBoxId + '/management/status'
            if (ask === 0) {
                publish(topic, {
                    user_id: store.getters.user.id,
                    client_id: store.getters.clientId
                })
            } else {
                publish(topic, {
                    user_id: store.getters.user.id,
                    client_id: store.getters.clientId,
                    ASK: 1
                })
            }
            this.timeout = setTimeout(this.stopBoxStatus, this.getboxstatustimeout -1000)
        },
        doorMsg(mode) {
            if(mode == false) {
                this.openAllBtnMsg = '全閉儲格'
                this.switchMsg = '全閉儲格模式'
            } else {
                this.openAllBtnMsg = '儲格全開'
                this.switchMsg = '儲格全開模式'
            }
        },
        openAllDoors() {
            this.showDialog('儲格開啟中', '開啟失敗，請再嘗試一次', 120000)
            this.controlMode = DOOR_CONTROL_MODE.OPEN_ALL
            let topic = 'box/' + this.boxId + '/doors/openall'
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId
            })
        },
        doorsStatus() {
            this.showDialog('格門狀態中', '格門狀態檢視失敗，請再嘗試一次', 50000)
            this.controlMode = DOOR_CONTROL_MODE.DOOR_STATUS
            let topic = 'box/' + this.boxId + '/doors/status'
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId
            }, 1)
        },
        finish() {
            this.$router.back()
        }
    },
    activated() {
        store.commit('setTitle', '櫃體設定')
        store.commit('setSideBtnType', SideBtnType.Back)
        this.init()
        this.watchReport()
        this.watchBoxStatus()
    },
    deactivated() {
        store.commit('setSideBtnType', SideBtnType.Navi)
        // unwatch store changes
        if (this.watchers.length > 0) {
            this.watchers.forEach(w => w())
            this.watchers = []
        }
        clearTimeout(this.timeout)
        clearTimeout(this.maximumTimeout)
        this.stopCountdown()
    }
}
</script>

<style scoped>
</style>
